import React from 'react'
import { Link } from 'react-router-dom'

import FeatureCard2 from '../components/feature-card2'
import './code-of-conduct.css'

const CodeOfConduct = (props) => {
    return (
        <div className="code-of-conduct-container">
            <div className="code-of-conduct-header">
                <header
                    data-thq="thq-navbar"
                    className="code-of-conduct-navbar-interactive"
                >
                    <Link to="/" className="code-of-conduct-logo">
                        SKYSUMMIT
                    </Link>
                    <div
                        data-thq="thq-navbar-nav"
                        className="code-of-conduct-desktop-menu"
                    >
                        <nav className="code-of-conduct-links">
                            <span className="code-of-conduct-nav3">Agenda</span>
                            <span className="code-of-conduct-nav5">Sponsors</span>
                        </nav>
                        <div className="code-of-conduct-buttons">
                            <a
                                href="https://sessionize.com/skysummit/"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="code-of-conduct-login button"
                            >
                                CFP
                            </a>
                            <a
                                href="https://buytickets.at/skysummitgbr"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="code-of-conduct-register button"
                            >
                                Registration
                            </a>
                        </div>
                    </div>
                    <div
                        data-thq="thq-burger-menu"
                        className="code-of-conduct-burger-menu"
                    >
                        <svg viewBox="0 0 1024 1024" className="code-of-conduct-icon">
                            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                        </svg>
                    </div>
                    <div
                        data-thq="thq-mobile-menu"
                        className="code-of-conduct-mobile-menu"
                    >
                        <div className="code-of-conduct-nav">
                            <div className="code-of-conduct-top">
                                <span className="code-of-conduct-logo1">SKYSUMMIT</span>
                                <div
                                    data-thq="thq-close-menu"
                                    className="code-of-conduct-close-menu"
                                >
                                    <svg
                                        viewBox="0 0 1024 1024"
                                        className="code-of-conduct-icon02"
                                    >
                                        <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                                    </svg>
                                </div>
                            </div>
                            <nav className="code-of-conduct-links1">
                                <span>About</span>
                                <span className="code-of-conduct-nav2">Speakers</span>
                                <span className="code-of-conduct-nav31">Agenda</span>
                                <span className="code-of-conduct-nav4">Registration</span>
                                <span className="code-of-conduct-nav51">Sponsors</span>
                            </nav>
                            <div className="code-of-conduct-buttons1">
                                <button className="code-of-conduct-login1 button">Login</button>
                                <button className="code-of-conduct-register1 button">
                                    Register
                                </button>
                            </div>
                        </div>
                        <div>
                            <svg
                                viewBox="0 0 950.8571428571428 1024"
                                className="code-of-conduct-icon04"
                            >
                                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                            </svg>
                            <svg
                                viewBox="0 0 877.7142857142857 1024"
                                className="code-of-conduct-icon06"
                            >
                                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                            </svg>
                            <svg
                                viewBox="0 0 602.2582857142856 1024"
                                className="code-of-conduct-icon08"
                            >
                                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                            </svg>
                        </div>
                    </div>
                </header>
            </div>
            <div className="code-of-conduct-hero">
                <div className="code-of-conduct-hero1">
                    <div className="code-of-conduct-container1">
                        <h1 className="code-of-conduct-hero-heading heading1">
                            Join us at SkySummit Berlin
                        </h1>
                        <span className="code-of-conduct-hero-sub-heading">
              July 24th &amp; 25th 2024
            </span>
                        <div className="code-of-conduct-btn-group">
                            <a
                                href="https://buytickets.at/skysummitgbr"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="code-of-conduct-hero-button1 button"
                            >
                                Register
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="code-of-conduct-banner">
                <h1 className="code-of-conduct-text">Code of Conduct</h1>
                <span className="code-of-conduct-text01">
          <span>
            SkySummit Berlin is committed to providing a safe, inclusive, and
            respectful environment for all participants. This Code of Conduct
            outlines our expectations for behavior, ensuring a positive
            experience for everyone attending the conference.
          </span>
          <br></br>
          <br></br>
          <span>
            <span
                dangerouslySetInnerHTML={{
                    __html: ' ',
                }}
            />
          </span>
          <span>
            <span
                dangerouslySetInnerHTML={{
                    __html: ' ',
                }}
            />
          </span>
        </span>
            </div>
            <div className="code-of-conduct-features">
                <h1 className="code-of-conduct-text07">Expected Behaviour</h1>
                <div className="code-of-conduct-container2">
                    <FeatureCard2
                        title="Respectful Interactions"
                        description="Treat all participants with respect and consideration, valuing diverse perspectives and experiences.."
                        rootClassName="rootClassName"
                    ></FeatureCard2>
                    <FeatureCard2
                        title="Inclusive Environment"
                        description=" Create an inclusive and welcoming atmosphere, avoiding any form of discrimination or harassment based on race, gender, sexual orientation, disability, religion, or any other protected status."
                        rootClassName="rootClassName3"
                    ></FeatureCard2>
                    <FeatureCard2
                        title="Collaboration"
                        description="Foster a spirit of collaboration and open communication, encouraging the sharing of ideas and knowledge among attendees."
                        rootClassName="rootClassName2"
                    ></FeatureCard2>
                    <FeatureCard2
                        title="Professionalism"
                        description="Maintain professionalism in all interactions, including both online and offline communication associated with the conference."
                        rootClassName="rootClassName1"
                    ></FeatureCard2>
                    <FeatureCard2
                        title="Compliance with Policies"
                        description="Abide by all applicable laws and conference policies, including those related to conduct, safety, and security."
                        rootClassName="rootClassName5"
                    ></FeatureCard2>
                </div>
            </div>
            <div className="code-of-conduct-features1">
                <h1 className="code-of-conduct-text08">Unacceptable Behavior</h1>
                <div className="code-of-conduct-container3">
                    <FeatureCard2
                        title="Harassment"
                        description="Harassment of any form, including but not limited to verbal, physical, or visual harassment, is not tolerated."
                        rootClassName="rootClassName4"
                    ></FeatureCard2>
                    <FeatureCard2
                        title="Discrimination"
                        description="Discrimination based on race, gender, sexual orientation, disability, religion, or any other protected status is strictly prohibited."
                        rootClassName="rootClassName6"
                    ></FeatureCard2>
                    <FeatureCard2
                        title="Intimidation"
                        description="Intimidating, bullying, or threatening behavior is not acceptable."
                        rootClassName="rootClassName7"
                    ></FeatureCard2>
                    <FeatureCard2
                        title="Unwanted Advances"
                        description="Unwanted and inappropriate advances, both physical and verbal, are not permitted."
                        rootClassName="rootClassName8"
                    ></FeatureCard2>
                    <FeatureCard2
                        title="Disruptions"
                        description="Disruptive or disrespectful behavior during sessions, workshops, or other conference activities will not be tolerated."
                        rootClassName="rootClassName9"
                    ></FeatureCard2>
                </div>
            </div>
            <div className="code-of-conduct-features2">
                <div className="code-of-conduct-container4">
                    <FeatureCard2
                        title="Reporting Violations"
                        description="If you experience or witness a violation of this Code of Conduct, please report it immediately to a conference organizer, staff member, or use the designated reporting channel provided during the event."
                        rootClassName="rootClassName10"
                    ></FeatureCard2>
                    <FeatureCard2
                        title="Consequences of Violation"
                        description="SkySummit Berlin reserves the right to take appropriate action, including but not limited to warning, expulsion from the event without a refund, and reporting to relevant authorities."
                        rootClassName="rootClassName11"
                    ></FeatureCard2>
                    <FeatureCard2
                        title="Acknowledgment"
                        description="By participating in SkySummit Berlin Cloud Conference, attendees, speakers, sponsors, and volunteers agree to abide by this Code of Conduct. SkySummit Berlin organizers will enforce this code throughout the event."
                        rootClassName="rootClassName12"
                    ></FeatureCard2>
                    <FeatureCard2
                        title="Contact"
                        description="If you have any questions or concerns regarding this Code of Conduct, please contact Rick Rackow at rick@skysummit.io."
                        rootClassName="rootClassName13"
                    ></FeatureCard2>
                </div>
            </div>
            <div className="code-of-conduct-footer">
                <footer className="code-of-conduct-footer1">
                    <div className="code-of-conduct-container5">
                        <span className="code-of-conduct-logo2">SKYSUMMIT</span>
                        <nav className="code-of-conduct-nav1 code-of-conduct-nav1"></nav>
                    </div>
                    <div className="code-of-conduct-separator"></div>
                    <div className="code-of-conduct-container6">
            <span className="code-of-conduct-text09">
              © 2023 SkySummit, All Rights Reserved.
            </span>
                        <div className="code-of-conduct-icon-group1">
                            <svg
                                viewBox="0 0 950.8571428571428 1024"
                                className="code-of-conduct-icon10"
                            >
                                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                            </svg>
                            <a
                                href="mailto:info@skysummit.io?subject=SkySummit Contact"
                                className="code-of-conduct-link"
                            >
                                <svg viewBox="0 0 1024 1024" className="code-of-conduct-icon12">
                                    <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default CodeOfConduct
