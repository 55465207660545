import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Home from './views/home'
import RegistrationThankYou from './views/registration-thank-you'
import NotFound from './views/not-found'
import CodeOfConduct from "./views/code-of-conduct";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={CodeOfConduct} exact path="/code-of-conduct"/>
        <Route
          component={RegistrationThankYou}
          exact
          path="/registration-thank-you"
        />
        <Route exact path="/agenda" render={() => {window.location.href="agenda.html"}} />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
