import React, {useEffect} from 'react'

import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import './home.css'
import GalleryCard1 from "../components/gallery-card1";

// Images
import amosWendorff from "../assets/images/amos-wendorff.jpg"
import anjaKunkel from "../assets/images/anja-kunkel.jpg"
import christosChatzis from "../assets/images/christos-chatzis.jpg"
import danielCiaglia from "../assets/images/daniel-ciaglia.png"
import davidFlanagan from "../assets/images/david-flanagan.jpeg"
import dimitrisKalaitzidis from "../assets/images/dimitris-kalaitzidis.jpg"
import erlendRushfeldt from "../assets/images/erlend-rushfeldt.jpg"
import ethanFerdosi from "../assets/images/ethan-ferdosi.jpg"
import felixJohn from "../assets/images/felix-john.jpg"
import florianMair from "../assets/images/florian-mair.png"
import florianStefan from '../assets/images/florian-stefan.jpg'
import jahnEhrhardt from "../assets/images/jan-ehrhardt.jpg"
import joachimAumann from "../assets/images/joachim-aumann.jpg"
import katerynaHrytsaienko from "../assets/images/kateryna-hrytsaienko.jpg"
import olivierBierlaire from "../assets/images/olivier-bierlaire.jpg"
import manuelDewald from "../assets/images/manuel-dewald.JPG"
import marcoPlaul from "../assets/images/marco-plaul.jpg"
import marcoZiech from "../assets/images/marco-ziech.jpg"
import marinaBurkhardt from "../assets/images/marina-burkhardt.jpg"
import martinSakowski from "../assets/images/martin-sakowski.jpg"
import michelleMeiLiPfister from "../assets/images/michelle-mei-li-pfister.jpg"
import neleLeaUhlemann from "../assets/images/nele-lea-uhlemann.jpg"
import nicolasFrankel from "../assets/images/nicolas-frankel.jpg"
import normanWeisenburger from "../assets/images/norman-weisenburger.jpg"
import piotrTrebacz from "../assets/images/piotr-trebacz.jpg"
import renatoLosio from "../assets/images/renato-losio.png"
import sameerParadkar from "../assets/images/sameer-paradkar.jpg"
import sohanMaheshwar from "../assets/images/sohan-maheshwar.jpg"
import timurBublik from "../assets/images/timur-bublik.jpg"
import vijaykumarJangamashetti from "../assets/images/vijaykumar-jangamashetti.jpg"
import yahyaFadhlulohAlFatih from "../assets/images/yahya-fadhluloh-al-fatih.png"

import summitLogo from "../assets/images/logo.jpg"

const Home = (props) => {

  return (
    <div className="home-container">
      <Helmet>
        <title>SkySummit - Berlin Cloud Conference</title>
        <meta
          name="description"
          content="SkySummit Berlin is the ultimate gathering for cloud enthusiasts, bringing together industry experts, thought leaders, and technology enthusiasts. With dedicated tracks for infrastructure, networking, data, and microservices, this conference offers unparalleled insights and networking opportunities. "
        />
        <meta property="og:title" content="SkySummit" />
        <meta
          property="og:description"
          content="SkySummit Berlin is the ultimate gathering for cloud enthusiasts, bringing together industry experts, thought leaders, and technology enthusiasts. With dedicated tracks for infrastructure, networking, data, and microservices, this conference offers unparalleled insights and networking opportunities. "
        />
      </Helmet>
      <div className="home-header">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <span className="home-logo">SKYSUMMIT</span>
          <div data-thq="thq-navbar-nav" className="home-desktop-menu">
            <nav className="home-links">
              <a href="#about">About</a>
              <Link to="/agenda" className="home-nav3">
                <span className="home-text">Agenda</span>
                <br></br>
              </Link>
              <a href="#speakers" className="home-nav2">
                Speakers
              </a>
              <a href="#sponsors" className="home-nav2">Sponsors</a>
              <Link to="/code-of-conduct" className="home-nav3">
                <span className="home-text">Code of Conduct</span>
                <br></br>
              </Link>
              <span className="home-nav5">Sponsors</span>
            </nav>
            <div className="home-buttons">
              <a
                  href="https://sessionize.com/skysummit/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-nav5 button"
              >
                CFP
              </a>
              <a
                href="https://buytickets.at/skysummitgbr/1073918"
                target="_blank"
                rel="noreferrer noopener"
                className="home-register button"
              >
                Registration
              </a>
            </div>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div className="home-nav">
              <div className="home-top">
                <span className="home-logo1">SKYSUMMIT</span>
                <div data-thq="thq-close-menu" className="home-close-menu">
                  <svg viewBox="0 0 1024 1024" className="home-icon02">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="home-links1">
                <span className="home-nav11">About</span>
                <span className="home-nav21">Speakers</span>
                <span className="home-nav31">Agenda</span>
                <span className="home-nav4">Registration</span>
                <span className="home-nav51">Sponsors</span>
              </nav>
              <div className="home-buttons1">
                <button className="home-login1 button">Login</button>
                <button className="home-register1 button" >Register</button>
              </div>
            </div>
            <div>
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon04">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon06">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon08">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div>
      <div className="home-hero">
        <div className="home-hero1">
          <div className="home-container1">
            <h1 className="home-hero-heading heading1">
              Join us at SkySummit Berlin
            </h1>
            <span className="home-hero-sub-heading">
              July 24th &amp; 25th 2024
            </span>
            <div className="home-btn-group">
              <a
                  href="https://buytickets.at/skysummitgbr/1073918"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-register button"
              >
                <button className="home-hero-button1 button">Register</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="home-details">
        <div id="about" className="home-details1">
          <div className="home-container2">
            <span className="home-text sectionTitle">
              <span>Details</span>
              <br></br>
            </span>
            <h2 className="home-details-heading heading2">
              About SkySummit Berlin
            </h2>
            <span className="home-details-sub-heading">
              <span className="home-text03">
                SkySummit Berlin is the ultimate gathering for cloud
                enthusiasts, bringing together industry experts, thought
                leaders, and technology enthusiasts. With dedicated tracks for
                infrastructure, networking &amp; data, and microservices &amp;
                Monoliths, this conference offers unparalleled insights and
                networking opportunities. Join us in Berlin in summer 2024 to
                unlock the potential of the cloud.
              </span>
              <br></br>
            </span>
          </div>
          <img
            alt="image"
            src={summitLogo}
            className="home-details-image"
          />
        </div>
      </div>
      <div id="speakers" className="home-features">
        <h1 className="home-text07">
          <span>Speakers</span>
          <br></br>
        </h1>
        <span className="home-text10"></span>
        <div className="home-gallery">
          <a
              href="https://linkedin.com/in/florian-stefan-90553a109/"
              target="_blank"
              rel="noreferrer noopener"
          >
          <GalleryCard1
              rootClassName="rootClassName"
              image_alt="Florian Stefan"
              title="Florian Stefan"
              subtitle="Staff Software Engineer @Flatiron Health"
              image_src={florianStefan}
              className="home-component"
              image_link="https://linkedin.com/in/florian-stefan-90553a109/"
          ></GalleryCard1>
          </a>
          <a
              href="https://linkedin.com/in/anja-kunkel-236534135/"
              target="_blank"
              rel="noreferrer noopener"
          >
            <GalleryCard1
                rootClassName="rootClassName1"
                className="home-component1"
                image_alt="Anja Kunkel"
                title="Anja Kunkel"
                subtitle="Principal Software Engineer @Mister Spex"
                image_src={anjaKunkel}
                image_link="https://linkedin.com/in/anja-kunkel-236534135/"
            ></GalleryCard1>
          </a>
          <a
              href="https://linkedin.com/in/danielciaglia/"
              target="_blank"
              rel="noreferrer noopener"
          >
            <GalleryCard1
                rootClassName="rootClassName2"
                className="home-component2"
                image_alt="Daniel Ciaglia"
                title="Daniel Ciaglia"
                subtitle="Consultant @sigterm.de Consulting"
                image_src={danielCiaglia}
                image_link="https://linkedin.com/in/danielciaglia/"
            ></GalleryCard1>
          </a>

            <a
                href="https://www.linkedin.com/in/rawkode"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName3"
                    className="home-component3"
                    image_alt="David Flanagan"
                    title="David Flanagan"
                    subtitle="Founder @Rawkode Academy"
                    image_src={davidFlanagan}
                    image_link="https://www.linkedin.com/in/rawkode"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/jan-ehrhardt-7a5602167"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName4"
                    className="home-component4"
                    image_alt="Jan Ehrhardt"
                    title="Jan Ehrhardt"
                    subtitle="Founder @Cozy Bytes GmbH"
                    image_src={jahnEhrhardt}
                    image_link="https://www.linkedin.com/in/jan-ehrhardt-7a5602167"
                ></GalleryCard1>
            </a>
          <a
                href="https://www.linkedin.com/in/nele-uhlemann-268b68191/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName5"
                    className="home-component5"
                    image_alt="Nele Lea Uhlemann"
                    title="Nele Lea Uhlemann"
                    subtitle="Developer Advocate @Fiberplane"
                    image_src={neleLeaUhlemann}
                    image_link="https://www.linkedin.com/in/nele-uhlemann-268b68191/"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/manueldewald/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName6"
                    className="home-component6"
                    image_alt="Manuel Dewald"
                    title="Manuel Dewald"
                    subtitle="Principal Software Engineer @Red Hat"
                    image_src={manuelDewald}
                    image_link="https://www.linkedin.com/in/manueldewald/"
                ></GalleryCard1>
            </a>
          <a
                href="https://www.linkedin.com/in/ekaterina-gricaenko"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName7"
                    className="home-component7"
                    image_alt="Kateryna Hrytsaienko"
                    title="Kateryna Hrytsaienko"
                    subtitle="Women Techmakers Ambassador @Google"
                    image_src={katerynaHrytsaienko}
                    image_link="https://www.linkedin.com/in/ekaterina-gricaenko"
                ></GalleryCard1>
            </a>
          <a
                href="https://de.linkedin.com/in/marco-ziech-898176241"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName8"
                    className="home-component8"
                    image_alt="Marco Ziech"
                    title="Marco Ziech"
                    subtitle="Principal Backend Architect @mobile.de"
                    image_src={marcoZiech}
                    image_link="https://de.linkedin.com/in/marco-ziech-898176241"
                ></GalleryCard1>
            </a>

            <a
                href="https://www.linkedin.com/in/rlosio/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName5"
                    className="home-component5"
                    image_alt="Renato Losio"
                    title="Renato Losio"
                    subtitle="Cloud Expert @cloudiamo.com"
                    image_src={renatoLosio}
                    image_link="https://www.linkedin.com/in/rlosio/"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/vijaykumarpj/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName6"
                    className="home-component6"
                    image_alt="Vijaykumar Jangamashetti"
                    title="Vijaykumar Jangamashetti"
                    subtitle="Senior Cloud Consulting Architect @Google"
                    image_src={vijaykumarJangamashetti}
                    image_link="https://www.linkedin.com/in/vijaykumarpj/"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/flomair/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName7"
                    className="home-component7"
                    image_alt="Florian Mair"
                    title="Florian Mair"
                    subtitle="Sr. Solutions Architect @AWS"
                    image_src={florianMair}
                    image_link="https://www.linkedin.com/in/flomair/"
                ></GalleryCard1>
            </a>
            <a
                href="https://sessionize.com/sameer-paradkar"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName8"
                    className="home-component8"
                    image_alt="Sameer Paradkar"
                    title="Sameer Paradkar"
                    subtitle="Chief Architect - Digital @Eviden"
                    image_src={sameerParadkar}
                    image_link="https://sessionize.com/sameer-paradkar"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/olivierbierlaire/?locale=en_US"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName5"
                    className="home-component5"
                    image_alt="Olivier Bierlaire"
                    title="Olivier Bierlaire"
                    subtitle="Founder @Carbonifer"
                    image_src={olivierBierlaire}
                    image_link="https://www.linkedin.com/in/olivierbierlaire/?locale=en_US"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/yahya-fadhlulloh-al-fatih-a568a455/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName6"
                    className="home-component6"
                    image_alt="Yahya Fadhluloh Al Fatih"
                    title="Yahya Fadhluloh Al Fatih"
                    subtitle="Head of Engineering Platform @eFishery"
                    image_src={yahyaFadhlulohAlFatih}
                    image_link="https://www.linkedin.com/in/yahya-fadhlulloh-al-fatih-a568a455/"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/joachim-aumann-8b4b12182/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName7"
                    className="home-component7"
                    image_alt="Joachim Aumann"
                    title="Joachim Aumann"
                    subtitle="Senior Solution Architect @AWS"
                    image_src={joachimAumann}
                    image_link="https://www.linkedin.com/in/joachim-aumann-8b4b12182/"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/amos-wendorff-67a778169/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName8"
                    className="home-component8"
                    image_alt="Amos Wendorff"
                    title="Amos Wendorff"
                    subtitle="Senior Cyber Security Design Specialist @SAP"
                    image_src={amosWendorff}
                    image_link="https://www.linkedin.com/in/amos-wendorff-67a778169/"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/timur-bublik/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName5"
                    className="home-component5"
                    image_alt="Timur Bublik"
                    title="Timur Bublik"
                    subtitle="Platform Engineering Lead @TIER Mobility "
                    image_src={timurBublik}
                    image_link="https://www.linkedin.com/in/timur-bublik/"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/mplaul"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName6"
                    className="home-component6"
                    image_alt="Marco Plaul"
                    title="Marco Plaul"
                    subtitle="Senior Solutions Architect @AWS"
                    image_src={marcoPlaul}
                    image_link="https://www.linkedin.com/in/mplaul"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/martinsakowski/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName7"
                    className="home-component7"
                    image_alt="Martin Sakowski"
                    title="Martin Sakowski"
                    subtitle="Senior Solutions Architect @Amazon Web Services"
                    image_src={martinSakowski}
                    image_link="https://www.linkedin.com/in/martinsakowski/"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/normalerweise/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName8"
                    className="home-component8"
                    image_alt="Norman Weisenburger"
                    title="Norman Weisenburger"
                    subtitle="Senior Solutions Architect @Amazon Web Services"
                    image_src={normanWeisenburger}
                    image_link="https://www.linkedin.com/in/normalerweise/"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/kalaitzidisdimitrios/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName5"
                    className="home-component5"
                    image_alt="Dimitrios Kalaitzidis"
                    title="Dimitrios Kalaitzidis"
                    subtitle="Director of Engineering @Orfium"
                    image_src={dimitrisKalaitzidis}
                    image_link="https://www.linkedin.com/in/kalaitzidisdimitrios/"
                ></GalleryCard1>
            </a>
            <a
                href="http://linkedin.com/in/christos-chatzis-57108089"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName6"
                    className="home-component6"
                    image_alt="Christos Chatzis"
                    title="Christos Chatzis"
                    subtitle="Associate Director of Engineering @Orfium"
                    image_src={christosChatzis}
                    image_link="http://linkedin.com/in/christos-chatzis-57108089"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/marina-burkhardt/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName7"
                    className="home-component7"
                    image_alt="Marina Burkhardt"
                    title="Marina Burkhardt"
                    subtitle="Solutions Architect @AWS"
                    image_src={marinaBurkhardt}
                    image_link="https://www.linkedin.com/in/marina-burkhardt/"
                ></GalleryCard1>
            </a>
            <a
                href="https://linkedin.com/in/sohanmaheshwar/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName8"
                    className="home-component8"
                    image_alt="Sohan Maheshwar"
                    title="Sohan Maheshwar"
                    subtitle="Developer Advocate Lead @Fermyon"
                    image_src={sohanMaheshwar}
                    image_link="https://linkedin.com/in/sohanmaheshwar/"
                ></GalleryCard1>
            </a>
            <a
                href=""
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName5"
                    className="home-component5"
                    image_alt="Felix John"
                    title="Felix John"
                    subtitle="Solutions Architect @AWS"
                    image_src={felixJohn}
                    image_link=""
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/michelle-mei-li/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName6"
                    className="home-component6"
                    image_alt="Michelle Mei-Li Pfister"
                    title="Michelle Mei-Li Pfister"
                    subtitle="Solutions Architect @AWS"
                    image_src={michelleMeiLiPfister}
                    image_link="https://www.linkedin.com/in/michelle-mei-li/"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/nicolasfrankel/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName7"
                    className="home-component7"
                    image_alt="Nicolas Fränkel"
                    title="Nicolas Fränkel"
                    subtitle="Developer Advocate for Apache APISIX"
                    image_src={nicolasFrankel}
                    image_link="https://www.linkedin.com/in/nicolasfrankel/"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/ethan-ferdosi/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName8"
                    className="home-component8"
                    image_alt="Ethan Ferdosi"
                    title="Ethan Ferdosi"
                    subtitle="Solutions Architect Leader @AWS Startups"
                    image_src={ethanFerdosi}
                    image_link="https://www.linkedin.com/in/ethan-ferdosi/"
                ></GalleryCard1>
            </a>
            <a
                href="https://linkedin.com/in/erlend-rushfeldt-b0a92a159"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName5"
                    className="home-component5"
                    image_alt="Erlend Rushfeldt"
                    title="Erlend Rushfeldt"
                    subtitle="Senior Consultant @Innofactor Norway"
                    image_src={erlendRushfeldt}
                    image_link="https://linkedin.com/in/erlend-rushfeldt-b0a92a159"
                ></GalleryCard1>
            </a>
            <a
                href="https://www.linkedin.com/in/pgtrebacz/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <GalleryCard1
                    rootClassName="rootClassName6"
                    className="home-component6"
                    image_alt="Piotr Trebacz"
                    title="Piotr Trebacz"
                    subtitle="Cloud Native & DevOps Advisor"
                    image_src={piotrTrebacz}
                    image_link="https://www.linkedin.com/in/pgtrebacz/"
                ></GalleryCard1>
            </a>

          {/*
          <GalleryCard1
              image_src="https://images.unsplash.com/photo-1579551053957-ee77f9b970c7?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDQ2fHx3b29kc3xlbnwwfHx8fDE2MjY0NDc1ODg&amp;ixlib=rb-1.2.1&amp;w=1000"
              rootClassName="rootClassName1"
          ></GalleryCard1>
          <GalleryCard1
              image_src="https://images.unsplash.com/photo-1425913397330-cf8af2ff40a1?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDN8fHdvb2RzfGVufDB8fHx8MTYyNjQ0NzU3Mw&amp;ixlib=rb-1.2.1&amp;w=1000"
              rootClassName="rootClassName2"
          ></GalleryCard1>
          <GalleryCard1
              image_src="https://images.unsplash.com/photo-1439853949127-fa647821eba0?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE4fHxuYXR1cmV8ZW58MHx8fHwxNjI2NDQ3ODAw&amp;ixlib=rb-1.2.1&amp;w=1000"
              rootClassName="rootClassName3"
          ></GalleryCard1>
          <GalleryCard1
              image_src="https://images.unsplash.com/photo-1529948723647-8b7bd77b441c?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDExfHxjbGlmZnxlbnwwfHx8fDE2MjY0NDc4MjQ&amp;ixlib=rb-1.2.1&amp;w=1000"
              rootClassName="rootClassName4"
          ></GalleryCard1>
          <GalleryCard1
              image_src="https://images.unsplash.com/photo-1553570739-330b8db8a925?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDI0fHxvY2VhbnxlbnwwfHx8fDE2MjY0NDc4ODQ&amp;ixlib=rb-1.2.1&amp;w=1000"
              rootClassName="rootClassName5"
          ></GalleryCard1>
          /*/}
        </div>
      </div>
      <div id="cfp" className="home-banner">
        <h1 className="home-text11">Call for Proposals</h1>
        <span className="home-text12">
          <span>CFP is closed now. </span>
          <br></br>
          <br></br>
          <span>Check out this years Agenda </span>
          <br></br>
        </span>
        <div className="home-btn-group1">
          <Link to="/agenda" className="home-link1 button">Agenda</Link>
          {/*
          <a
              href="https://sessionize.com/skysummit/"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link1 button"
          >
            CFP
          </a>
          */}
        </div>
      </div>
      <div className="home-banner1">
        <div className="home-banner2">
          <h1 className="home-banner-heading heading2">
            Discover the Future of Cloud Technology
          </h1>
          <span className="home-banner-sub-heading">
            Explore the latest trends in infrastructure, networking, data, and
            microservices and meet amazing people.
          </span>
          <a
              href="https://buytickets.at/skysummitgbr/1073918"
              target="_blank"
              rel="noreferrer noopener"
              className="home-register button"
          >
            <button className="home-banner-button button">Register</button>
          </a>

        </div>
      </div>
      <div className="home-footer">
        <footer className="home-footer1">
          <div className="home-container3">
            <span className="home-logo2">SKYSUMMIT</span>
            <nav className="home-nav1 home-nav1">
              <a href="#about" className="home-nav12">
                About
              </a>
              <a href="#speakers" className="home-nav22">
                Speakers
              </a>
              <span className="home-nav32">Agenda</span>
              <a
                  href="https://www.tickettailor.com/events/skysummitgbr/1073918"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-nav41"
              >
                Registration
              </a>
              <a
                  href="mailto:sponsor@skysummit.io?subject=Sponsorship Inquiry SkySummit"
                  className="home-nav52"
              >
                <span>Sponsorship</span>
                <br></br>
              </a>
            </nav>
          </div>
          <div className="home-separator"></div>
          <div className="home-container4">
            <span className="home-text20">
              © 2024 SkySummit, All Rights Reserved.
            </span>
            <div className="home-icon-group1">
              <a
                  href="https://twitter.com/SkySummitBerlin"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link4"
              >
                <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="home-icon10"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
              </a>
              <a
                  href="mailto:info@skysummit.io?subject=SkySummit Contact"
                  className="home-link4"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon12">
                  <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                </svg>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
      )
}

export default Home
